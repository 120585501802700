const GuideLines = [
  "Download Admit Cards",
  "Download Admission Form",
  "High School Syllabus",
  "Intermediate Syllabus",
  "Duplicate Marksheet & Migration",
  "Online Result Verification",
];

export default GuideLines;
