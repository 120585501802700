import React from "react";
import Login from "./Pages/Login";

const App = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default App;
