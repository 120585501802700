const Languages = [
  { value: "ASSAMESE", label: "ASSAMESE" },
  { value: "BENGALI", label: "BENGALI" },
  { value: "BODO", label: "BODO" },
  { value: "DOGRI", label: "DOGRI" },
  { value: "ENGLISH ", label: "ENGLISH " },
  { value: "GUJARATI", label: "GUJARATI" },
  { value: "HINDI ", label: "HINDI " },
  { value: "KANNADA", label: "KANNADA" },
  { value: "KASHMIRI", label: "KASHMIRI" },
  { value: "KONKANI", label: "KONKANI" },
  { value: "MAITHILI", label: "MAITHILI" },
  { value: "MALAYALAM", label: "MALAYALAM" },
  { value: "MARATHI", label: "MARATHI" },
  { value: "MEITEI", label: "MEITEI" },
  { value: "NEPALI", label: "NEPALI" },
  { value: "ODIA", label: "ODIA" },
  { value: "PUNJABI", label: "PUNJABI" },
  { value: "SANSKRIT", label: "SANSKRIT" },
  { value: "SANTALI", label: "SANTALI" },
  { value: "SINDHI", label: "SINDHI" },
  { value: "TAMIL", label: "TAMIL" },
  { value: "TELUGU", label: "TELUGU" },
  { value: "URDU", label: "URDU" },
  { value: "GUJRATI", label: "GUJRATI" },
  { value: "PANJABI", label: "PANJABI" },
];

const secondoryOptionalSubjects = [
  { value: "HISTORY", label: "HISTORY" },
  { value: "GEOGRAPHY ", label: "GEOGRAPHY " },
  { value: "PHYSICAL SCIENCE ", label: "PHYSICAL SCIENCE " },
  { value: "LIFE SCIENCE ", label: "LIFE SCIENCE " },
  { value: "BIOLOGY ", label: "BIOLOGY " },
  { value: "COMPUTER SCIENCE ", label: "COMPUTER SCIENCE " },
  { value: "STATISTICS", label: "STATISTICS" },
  { value: "MATHEMATICS", label: "MATHEMATICS" },
  { value: "SCIENCE ", label: "SCIENCE " },
  { value: "DRAWING AND CRAFT ", label: "DRAWING AND CRAFT " },
  { value: "SOCIAL SCIENCE ", label: "SOCIAL SCIENCE " },
  {
    value: "HEALTH AND PHYSICAL EDU ",
    label: "HEALTH AND PHYSICAL EDU ",
  },
  { value: "WORK EDUCATION", label: "WORK EDUCATION" },
  { value: "SINGING", label: "SINGING" },
  { value: "SCIENCE AND TECH", label: "SCIENCE AND TECHNOLOGY" },
];

const scienceSubjects = [
  { value: "PHYSICS", label: "PHYSICS" },
  { value: "CHEMISTRY", label: "CHEMISTRY" },
  { value: "BIOLOGY", label: "BIOLOGY" },
  { value: "MATHEMATICS", label: "MATHEMATICS" },
  { value: "STATISTICS", label: "STATISTICS" },
  { value: "COMPUTER SCIENCE", label: "COMPUTER SCIENCE" },
  {
    value: "SCIENCE AND TECH",
    label: "SCIENCE AND TECH",
  },
  { value: "APPLIED MATHEMATICS", label: "APPLIED MATHEMATICS" },
  { value: "NUTRITION", label: "NUTRITION" },
];

const commerceSubjects = [
  { value: "ACCOUNTANCY", label: "ACCOUNTANCY" },
  { value: "BUSINESS ORGANISATION", label: "BUSINESS ORGANISATION" },
  { value: "ECONOMICS", label: "ECONOMICS" },
  { value: "BUSINESS MATHEMATICS", label: "BUSINESS MATHEMATICS" },
  { value: "COMPUTER SCIENCE", label: "COMPUTER SCIENCE" },
  { value: "STATISTICS", label: "STATISTICS" },
  { value: "MATHEMATICS", label: "MATHEMATICS" },
  { value: "COMMERCE", label: "COMMERCE" },
  { value: "HISTORY", label: "HISTORY" },
];

const artsSubjects = [
  { value: "HISTORY", label: "HISTORY" },
  { value: "GEOGRAPHY", label: "GEOGRAPHY" },
  { value: "POLITICAL SCIENCE ", label: "POLITICAL SCIENCE " },
  { value: "SOCIOLOGY ", label: "SOCIOLOGY " },
  { value: "PSYCHOLOGY", label: "PSYCHOLOGY" },
  { value: "EDUCATION ", label: "EDUCATION " },
  { value: "ECONOMICS ", label: "ECONOMICS " },
  { value: "FINE ARTS ", label: "FINE ARTS " },
  { value: "PHYSICAL EDUCATION", label: "PHYSICAL EDUCATION" },
  { value: "WORK EDUCATION ", label: "WORK EDUCATION " },
  { value: "HOME SCIENCE", label: "HOME SCIENCE" },
  { value: "SANSKRIT ", label: "SANSKRIT " },
  {
    value: "JOURNALISM AND MASS COMMUNICATION",
    label: "JOURNALISM AND MASS COMMUNICATION",
  },
  { value: "MUSIC", label: "MUSIC" },
  { value: "PHILOSOPHY", label: "PHILOSOPHY" },
];
export {
  Languages,
  secondoryOptionalSubjects,
  scienceSubjects,
  commerceSubjects,
  artsSubjects,
};
