const Icons = [
  "https://res.cloudinary.com/dgkw9jhtl/image/upload/v1710182852/vokqjs8cy7zrfldyvyyl.png",
  "https://res.cloudinary.com/dgkw9jhtl/image/upload/v1710182869/lbuknf3acf6pdqz0dozk.png",
  "https://res.cloudinary.com/dgkw9jhtl/image/upload/v1710182885/n0rgffa8otzq5adlfozc.png",
  "https://res.cloudinary.com/dgkw9jhtl/image/upload/v1710182901/rpk0tdzj8zvhhbsb8zrt.png",
  "https://res.cloudinary.com/dgkw9jhtl/image/upload/v1710182916/wszkryqllddgzv5rlr56.png",
  "https://res.cloudinary.com/dgkw9jhtl/image/upload/v1710182933/m2xx3fblkcd6bqkipcy6.png",
];

export default Icons;
